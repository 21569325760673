body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  display: flex;
  justify-content: center;
  background-image: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  min-height: 100vh;
  position: relative;
  padding-bottom: 15rem;

}

.logo {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-100%, 100%);
}

.logo img {
  width: 100px;
}

.router {
  width: 100%;
  display: flex;
  justify-content: center;

}

.form {
  width: 60%;
  margin: 10rem;
}

@media only screen and (max-width: 1100px) {
  .form {
    width: 80%;
  }
}

.name-label {
  color: white;
  margin-bottom: 1rem;
}

.name-input {
  background-color: transparent;
  border: 1px solid white;
  border-radius: 3px;
  font-size: 1.5rem;
  color: white;
  padding: 1rem;
}

.question {
  margin: 3rem 0;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px
}

.question-title {
  margin: 1rem 0 1.5rem 0;
  color: white;
  font-size: 1.5rem;
}

.answer-box {
  font-size: 0.9rem;
}

.submit-button {
  background-color: transparent;
  border: 1px solid white;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
}

.error-messages {
  color: white;
  margin-top: 1rem;
}

.bear {
  position: absolute;
  bottom: 0;
  right: 10%;
  opacity: 0.6;
}

.chart-title {
  color: white;
  text-transform: uppercase;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

